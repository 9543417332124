<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
          <CCol col="6" style="font-size: 25px">عرض بيانات الفاتورة</CCol>
        </CCardHeader>
        <CCardBody>
          <div>
            <div class="row">
              <div class="col-md-6">
                <table class="table table-bordered">
                    <tr >
                      <th>اسم المورد</th>
                      <td>{{ invoice.supplier.name }}</td>
                    </tr>
                </table>
              </div>
              <div class="col-md-12" v-if="invoice.items.length > 0">
                <h3>التفاصيل</h3>
                 <table class="table table-bordered">
                    <tr >
                        <th>المنتج</th>
                        <th>الحجم</th>
                        <th>اللون</th>
                        <th>الكمية</th>
                        <th>السعر</th>
                    </tr>
                    <tr v-for="(item, index) in invoice.items" v-bind:key="index">
                        <td>{{ item.product.name }}</td>
                        <td>{{ item.size.name }}</td>
                        <td>{{ item.color.name }}</td>
                        <td>{{ item.amount }}</td>
                        <td>{{ item.price | number }} QAR</td>
                    </tr>
                </table>
              </div>
            </div>
            <CButton color="warning" @click="goBack">رجوع</CButton>
          </div>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
export default {
  name: "ShowInvoice",
  data() {
    return {
      invoice: [],
      image: "",
    };
  },
  created() {
    console.log(this.$route.params.id);
    this.$http
      .get(`${process.env.VUE_APP_URL}invoices/${this.$route.params.id}`)
      .then((response) => {
        console.log("response", response.data.data);
        this.invoice = response.data.data;
      });
  },
  methods: {
    goBack() {
      this.invoicesOpened
        ? this.$router.go(-1)
        : this.$router.push({ path: "/invoices" });
    },
  },
};
</script>
<style scoped>
th {
    background-color: #3c4b64;
    color: #fff;
}
</style>
